.SuccessMessage {
    text-align: center;
    padding: 2em 0;
}

.dummy-positioning {
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.SuccessIcon {
    display: inline-block;
    width: 8em;
    height: 8em;
    font-size: 20px;
    border-radius: 50%;
    border: 4px solid #96df8f;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    transform-origin: center;
    -webkit-animation: showSuccess 180ms ease-in-out;
    animation: showSuccess 180ms ease-in-out;
    transform: scale(1);
}

.SuccessIcon__Tip, .SuccessIcon__Long {
    display: block;
    position: absolute;
    height: 4px;
    background-color: #96df8f;
    border-radius: 10px;
}
.SuccessIcon__Tip {
    width: 2.4em;
    top: 4.3em;
    left: 1.4em;
    transform: rotate(45deg);
    -webkit-animation: tipInPlace 300ms ease-in-out;
    animation: tipInPlace 300ms ease-in-out;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 180ms;
    animation-delay: 180ms;
    visibility: hidden;
}
.SuccessIcon__Long {
    width: 4em;
    transform: rotate(-45deg);
    top: 3.7em;
    left: 2.75em;
    -webkit-animation: longInPlace 140ms ease-in-out;
    animation: longInPlace 140ms ease-in-out;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    visibility: hidden;
    -webkit-animation-delay: 440ms;
    animation-delay: 440ms;
}

@-webkit-keyframes showSuccess {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@keyframes showSuccess {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}
@-webkit-keyframes tipInPlace {
    from {
        width: 0;
        top: 0;
        left: -1.6em;
    }
    to {
        width: 2.4em;
        top: 4.3em;
        left: 1.4em;
        visibility: visible;
    }
}
@keyframes tipInPlace {
    from {
        width: 0;
        top: 0;
        left: -1.6em;
    }
    to {
        width: 2.4em;
        top: 4.3em;
        left: 1.4em;
        visibility: visible;
    }
}
@-webkit-keyframes longInPlace {
    from {
        width: 0;
        top: 5.1em;
        left: 3.2em;
    }
    to {
        width: 4em;
        top: 3.7em;
        left: 2.75em;
        visibility: visible;
    }
}
@keyframes longInPlace {
    from {
        width: 0;
        top: 5.1em;
        left: 3.2em;
    }
    to {
        width: 4em;
        top: 3.7em;
        left: 2.75em;
        visibility: visible;
    }
}