.MyCarousel {
    width: 400px;
    height: 250px;
    position: relative;
    margin: 0 auto 30px;
    perspective: 1100px;
}
.MyCarousel__container {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition: transform 1s;
    transform: translateZ(-500px);
}
.MyCarousel__slide {
    display: block;
    position: absolute;
    width: 160px;
    height: 80%;
    left: 100px;
    top: 10px;
    text-align: center;
    margin: 0;
    background-color: white;
    padding: 6px;
    font-family: 'Roboto', sans-serif;
    border-radius: 9px;
    box-shadow: 1px 1px 8px -1px rgba(189,189,189,0.75);
    transition: all 0.1s ease-in-out;
}
.MyCarousel__slideActive {
    border: solid 3px #1b3c7c;
    transform: scale(1.2);
}
.MyCarousel__slide:hover {
    cursor: pointer;
    background-color: #f5f5f5;
}
.MyCarousel__slide:nth-child(1) {
    transform: rotateY(0deg) translateZ(500px);
}
.MyCarousel__slide:nth-child(2) {
    transform: rotateY(24deg) translateZ(500px);
}
.MyCarousel__slide:nth-child(3) {
    transform: rotateY(48deg) translateZ(500px);
}
.MyCarousel__slide:nth-child(4) {
    transform: rotateY(72deg) translateZ(500px);
}
.MyCarousel__slide:nth-child(5) {
    transform: rotateY(96deg) translateZ(500px);
}
.MyCarousel__slide:nth-child(6) {
    transform: rotateY(120deg) translateZ(500px);
}
.MyCarousel__slide:nth-child(7) {
    transform: rotateY(144deg) translateZ(500px);
}
.MyCarousel__slide:nth-child(8) {
    transform: rotateY(168deg) translateZ(500px);
}
.MyCarousel__slide:nth-child(9) {
    transform: rotateY(192deg) translateZ(500px);
}
.MyCarousel__slide:nth-child(10) {
    transform: rotateY(216deg) translateZ(500px);
}
.MyCarousel__slide:nth-child(11) {
    transform: rotateY(240deg) translateZ(500px);
}
.MyCarousel__slide:nth-child(12) {
    transform: rotateY(264deg) translateZ(500px);
}
.MyCarousel__slide:nth-child(13) {
    transform: rotateY(288deg) translateZ(500px);
}
.MyCarousel__slide:nth-child(14) {
    transform: rotateY(312deg) translateZ(500px);
}
.MyCarousel__slide:nth-child(15) {
    transform: rotateY(336deg) translateZ(500px);
}
@keyframes rotateInY {
    0% {
        transform: translateZ(-500px) rotateY(0deg);
    }
    100% {
        transform: translateZ(-500px) rotateY(360deg);
    }
}
.MyCarousel__icon {
    width: 80px;
    height: 80px;
    margin: 9px auto 0;
    border-radius: 50%;
    position: relative;
    background: rgb(186,186,186);
    background: linear-gradient(131deg, rgba(186,186,186,1) 0%, rgba(246,246,246,1) 53%, rgba(255,255,255,1) 100%);
}
.MyCarousel__icon::before {
    content: '';
    border-radius: 50%;
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 30px;
    height: 20%;
    width: 60%;
    box-shadow: 35px -4px 4px 0 rgba(0,0,0,0.4);
    transform: skewX(60deg);
}
.MyCarousel__icon object {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: invert(49%) sepia(18%) saturate(3646%) hue-rotate(160deg) brightness(100%) contrast(98%);
}
.MyCarousel__badge {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #1b3c7c;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    font-size: 0.7rem;
}
.MyCarousel__title {
    font-size: 0.9rem;
    color: #2a2a2a;
    font-weight: 500;
    margin-top: 15px;
    display: block;
}
.MyCarousel__prev {
    position: absolute !important;
    bottom: -50px;
    right: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
}
.MyCarousel__next {
    position: absolute !important;
    bottom: -50px;
    left: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
}
