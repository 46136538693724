.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.input {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: 100%;
  padding: 1rem;
}
.impexp {
  margin-top: 3rem;
  display: flex;
  gap: 4em;
}
