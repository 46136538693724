.basic {
  width: 100%;
  height: 100%;
  background-color: white;
}
.info {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  margin-top: 0.4rem;
}
.left {
  width: 48%;
  display: flex;
  flex-direction: column;
}

.general {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.required {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
}
.input {
  margin: 0.5rem 0;
}
.optional {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-top: 0.5rem;
}
.images {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  align-content: space-between;
}
.avatar {
  margin-bottom: 1rem;
}
.cover {
  margin-bottom: 1rem;
}

.cover, .avatar {
  width: 45%; /* esto define el ancho de cada elemento a un 50% del contenedor */
}
.right {
  width: 48%;
  background-color: rgb(233, 229, 229);
  position: relative;
}
.phone {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 75%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.preview {
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 5%;
  padding: 1rem;
  margin-top: 1rem;
}
.inside {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.photo {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 0.5rem;
}
.covers {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90px;
  margin: 1rem 0;
}
.coverright {
  width: 60%;
  height: 100%;
}
.avatarright {
  display: flex;
  align-items: center;
}
.little {
  width: 35px;
  height: 35px;
  margin-right: 0.6rem;
}
.bottom {
  display: flex;
  flex-direction: column;
}
.avatarleft {
  width: 35px;
  height: 35px;
}
.coverleft {
  width: 35px;
  height: 35px;
}
