.content {
  height: 92vh;
  margin-top: 4rem;
  overflow-y: scroll;
}
.onas {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.heading {
  text-align: center;
  font-size: 1.8rem;
  display: flex;
  padding: 0 1em;
  flex: 1;
  justify-content: center;
}
.books {
  display: flex;
  justify-content: center;
}
.book {
  padding: 2rem;
  color: #04b8f4;
}
.operations {
  background-color: rgb(235, 230, 230);
  height: 80vh;
  padding: 3rem;
}
.inside {
  height: 80vh;
  display: flex;
}
.crud {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 75%;
}
.holder {
  background-color: white;
  display: flex;
  position: relative;
  padding: 0.5rem;
  height: 22%;
  justify-content: space-between;
  align-items: center;
  width: 95%;
}
.sticker {
  background-color: #04b8f4;
  position: absolute;
  color: white;
  font-weight: 400;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  left: 2rem;
  top: -1rem;
  font-size: 0.8rem;
  width: 7rem;
}
.text {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.info {
  width: 85%;
}
.button {
  width: 30%;
  display: flex;
}
.space {
  letter-spacing: 1px;
}
.right {
  width: 25%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: space-between;
  font-size: 0.9rem;
}
@media only screen and (max-width: 1300px) {
  .info {
    font-size: 0.7rem;
  }
  .buttonresize {
    font-size: 0.6rem;
  }
  .right {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 1000px) {
  .info {
    font-size: 0.5rem;
  }
  .buttonresize {
    font-size: 0.5rem;
  }
  .right {
    font-size: 0.5rem;
  }
}

@media screen and (max-width: 900px) {
  .info {
    font-size: 0.5rem;
  }
  .buttonresize {
    font-size: 0.35rem;
  }
  .right {
    font-size: 0.5rem;
  }
}
