.build {
  display: flex;
  width: 96vw;
  height: 100vh;
  background-color: white;
}
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.stepper{
  margin-top: 15px;
}
