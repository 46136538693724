.content {
  width: 90vw;
  height: 92vh;
  margin-top: 4rem;
}
.crud {
  display: flex;
  flex-direction: column;
}
.top {
  display: flex;
  justify-content: space-between;
  padding: 1em 3em;
}

.type {
  display: flex;
  align-items: center;
}
.new {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}
.icontype {
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65vw;
  background-color: white;
  box-shadow: 24px;
  padding: 1rem;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  padding-right: 16px;
}
.modaltop {
  display: flex;
  justify-content: space-between;
}
.modalbuttom {
  display: block;
}
.buttom {
  height: 70vh;
  padding: 0 1em;
  margin: 0 2em;
}

.DataTable{
    width: 86.5vw;
    height: 90vh;
    margin-top: -1rem;
    border-top-left-radius: 0px;
    background-color: rgb(255, 255, 255);
    overflow-y: scroll;
}

.DataTable2 {
    justify-content: left;
    margin: 0em 5rem;
}
