.photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid grey;
  margin: 1rem auto;
}
.content {
  background-color: rgb(224, 219, 219);
  border-top-left-radius: 50px;
  width: 83vw;
  height: 92vh;
  margin-top: 4rem;
}
.cases {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: nowrap;
  height: 91vh;
}
.case {
  display: flex;
  width: 100%;
  height: 28vh;
  justify-content: space-evenly;
}
.project {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 1rem;
  width: 25%;
  box-shadow: 2px 3px 4px 4px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  padding: 0.6rem;
}
.image {
  width: 3.5rem;
  height: 3.5rem;
}
.title {
  font-weight: bold;
  font-size: 1.3rem;
  text-align: center;
  margin-top: 1.5rem;
}
.subtitle {
  color: grey;
  font-weight: 400;
  font-size: 0.8rem;
  text-align: center;
  margin-top: 0.5rem;
  width: 100%;
  word-wrap: break-word;
}
.icon {
  position: absolute;
  height: 35px;
  width: 35px;
  top: -2rem;
}
.sticker {
  position: absolute;
  background-color: #04b8f4;
  color: white;
  font-weight: 400;
  letter-spacing: 0.2rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  right: -1rem;
  top: -1.5rem;
  font-size: 1.1rem;
}
@media only screen and (max-width: 1300px) {
  .cases {
    width: 95%;
    justify-content: space-evenly;
  }
  .title {
    margin-top: 0;
    font-size: 0.9rem;
  }
  .image {
    width: 2.5em;
    height: 2.5em;
  }
  .subtitle {
    font-size: 0.7rem;
  }
  .photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid grey;
    margin: 1rem auto;
  }
}

@media screen and (max-width: 900px) {
  .cases {
    width: 90%;
    justify-content: space-evenly;
  }
  .title {
    margin-top: 0;
    font-size: 0.8rem;
  }
  .image {
    width: 2.5em;
    height: 2em;
  }
  .subtitle {
    font-size: 0.6rem;
  }
  .photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid grey;
    margin: 1rem auto;
  }
  .sticker {
    position: absolute;
    background-color: #04b8f4;
    color: white;
    font-weight: 400;
    letter-spacing: 0.2rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    right: -1rem;
    top: -1.5rem;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 800px) {
  .cases {
    width: 90%;
    justify-content: space-evenly;
  }
  .title {
    margin-top: 0;
    font-size: 0.8rem;
  }
  .image {
    width: 2em;
    height: 2em;
  }
  .subtitle {
    font-size: 0.5rem;
  }
  .photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid grey;
    margin: 1rem auto;
  }
}
