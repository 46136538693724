body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto";
  background-image: url(./assets/background.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  height: 100vh;
}
p {
  padding: 0;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
