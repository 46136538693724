.content {
  width: 89.5vw;
  height: 92vh;
  margin-top: 4rem;
  border-top-left-radius: 50px;
  background-color: rgb(224, 219, 219);
  overflow-y: scroll;
}
.survey_template {
  display: flex;
  flex-direction: column;
}
.heading {
  margin: 2em 4em;
}
.icon {
  width: 48px;
  height: 48px;
  object-fit: cover;
}
.text {
  flex: 1 1 0%;
}
.templates {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: white;
  margin: 0 4em;
  border-radius: 8px;
  align-items: stretch;
  padding: 1.5em;
  justify-content: center;
  box-shadow: rgb(79 81 110 / 30%) 0px 2px 4px;
  gap: 1em;
  margin-bottom: 3em;
  padding-bottom: 2em;
}
.template {
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(79 81 110 / 30%) 0px 5px 10px;
  border-radius: 0 0 8px 8px;
  height: 272px;
  justify-content: space-between;
}

.title {
  background-color: #03aae4;
  padding: 24px 28px;
  border-radius: 8px 8px 0 0;
  color: white;
  display: flex;
  gap: 1em;
  align-items: center;
}
.description {
  padding: 24px 20px;
  font-size: 14px;
  line-height: 20px;
  color: #686d84;
  font-weight: 300;
}
.bottom {
  display: flex;
  padding: 24px 20px;
  align-items: center;
  gap: 0.5em;
  color: #03aae4;
}
.create {
  display: flex;
  padding: 24px 20px;
  align-items: center;
  gap: 0.5em;
  color: #03aae4;
  margin-top: 2.5em;
}
