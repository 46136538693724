.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #03aae4;
  padding: 0.5rem;
  gap: 0.5rem;
}
.wrapper {
  margin: 1rem auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.question {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  padding: 1rem;
  align-items: center;
}
.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 0.5em;
}
.form {
  display: flex;
  flex-direction: row;
  flex: 3;
  margin-left: 1em;
  flex-wrap: wrap;
}
.stepper {
  position: fixed;
  display: flex;
  background-color: #fff;
  width: 96.5vw;
  left: 0;
  bottom: 0;
  padding: 18px 25px;
  box-shadow: -4px -1px 5px rgb(0 0 0 / 10%);
  gap: 1em;
  justify-content: flex-end;
}
.answer {
  overflow-y: scroll;
  margin: 1rem auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

@media only screen and (max-width: 400px) {
  .question {
    flex-direction: column;
    gap: 0.5em;
  }
  .info {
    flex-direction: row;
  }
}
