.UserAdministratorPage {
  width: 89.5vw;
  height: 92vh;
  margin-top: 4rem;
  border-top-left-radius: 50px;
  background-color: rgb(224, 219, 219);
  overflow-y: scroll;
}
.UserAdministratorPage__content {
  margin: 2em 4em;
}