.content {
  width: 89.5vw;
  height: 92vh;
  margin-top: 4rem;
  border-top-left-radius: 50px;
  background-color: rgb(224, 219, 219);
  overflow-y: scroll;
}
.survey_template {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  margin-bottom: 3em;
}
.heading {
  display: flex;
  justify-content: space-between;
  background-color: white;
  margin: 2em 4em;
  align-items: center;
  min-height: 64px;
  max-height: 80px;
  border-radius: 8px;
  padding: 15px;
  box-shadow: rgb(79 81 110 / 30%) 0px 2px 4px;
  gap: 1em;
}
.icon {
  width: 48px;
  height: 48px;
  object-fit: cover;
}
.text {
  flex: 1 1 0%;
}

.data {
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin: 0 4em;
  padding: 0;
  max-width: 100%;
  flex: 0 0 100%;
  position: relative;
}

.display {
  display: flex;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: rgb(79 81 110 / 30%) 0px 2px 4px;
  justify-content: center;
}
.stepper {
  display: flex;
  flex-basis: 70%;
  max-width: 100%;
}
.impexp {
  display: flex;
  flex-basis: 100%;
  justify-content: flex-end;
  gap: 1em;
  margin-bottom: 0.5em;
}
.modal {
  width: 900px;
  background-color: white;
  box-shadow: 24px;
  padding: 1rem;
  overflow: auto;
  margin: 0 auto;
}
.modaltop {
  display: flex;
  justify-content: flex-end;
}
.modalbuttom {
  display: flex;
  flex-direction: column;
}
.bottom {
  position: fixed;
  display: flex;
  background-color: #fff;
  width: 96.5vw;
  left: 0;
  bottom: 0;
  padding: 18px 25px;
  box-shadow: -4px -1px 5px rgb(0 0 0 / 10%);
  justify-content: flex-end;
  gap: 1em;
}
.form {
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  width: 100%;
}
