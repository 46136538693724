.Embed_container {
  height: 90vh;
  width: 89.5vw;
}
.Embed_container iframe {
  border: none;
}

.content {
  height: 92vh;
  margin-top: 4rem;
  overflow-y: scroll;
}
