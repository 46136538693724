.discussion {
  width: 100%;
  background-color: white;
  height: 90vh;
  overflow-y: scroll;
}
.content {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.header {
  display: flex;
  justify-content: space-around;
}
.left {
  display: flex;
  align-items: center;
}
.right {
  display: flex;
  align-items: center;
  margin-right: 3rem;
}
.group {
  margin-left: 1rem;
}
.group div:global(.MuiAvatar-root) {
  width: 30px;
  height: 30px;
}
.grey {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  background-color: rgb(233, 229, 229);
  width: 80%;
  margin-top: 1rem;
  border-radius: 1rem;
}
.layout {
  display: flex;
  width: 100%;
}
.leftbox {
  flex: 2;
  display: flex;
  justify-content: center;
}
.photo {
  width: 8rem;
  height: 8rem;
  object-fit: cover;
  border-radius: 0.5rem;
  padding: 1rem;
}
.rightbox {
  display: flex;
  flex-direction: column;
  flex: 8;
  margin-left: 2rem;
  justify-content: space-evenly;
}
.impexp {
  padding: 0 4rem;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 35%;
  transform: translate(-50%, -50%);
  width: 45%;
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
}

.modaltop {
  display: flex;
  justify-content: space-between;
}
.modalbuttom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 75%;
}
.blocks {
  padding: 1rem;
  flex-basis: 23%;
  height: 75%;
  border: 2px rgb(219, 215, 215) solid;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  cursor: pointer;
}
.blocks:hover {
  background-color: #cee7f0;
}
.templatemodal {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 65%;
  transform: translate(-50%, -50%);
  width: 25%;
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
}
.templatemodalbuttom {
  display: flex;
  flex-direction: column;
  height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
}
.templateblocks {
  width: 95%;
  min-height: 60px;
  border: 2px rgb(219, 215, 215) solid;
  display: flex;
  cursor: pointer;
  margin: 0.4rem 0;
}
.templatelayout {
  width: 100%;
  display: flex;
  height: 00px;
}
.templatephoto {
  height: 60px;
  object-fit: cover;
}
.templatecontent {
  display: flex;
  flex-direction: column;
  padding: 0.2rem 0.5rem;
}
