.content {
  width: 89.5vw;
  height: 92vh;
  margin-top: 4rem;
  border-top-left-radius: 50px;
  background-color: rgb(231, 231, 231);
  overflow-y: scroll;
}
.journey {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.heading {
  display: flex;
  justify-content: space-between;
  background-color: white;
  margin: 2em 5em;
  align-items: center;
  min-height: 64px;
  max-height: 80px;
  border-radius: 8px;
  padding: 15px;
  box-shadow: rgb(79 81 110 / 30%) 0px 2px 4px;
  margin-bottom: 1em;
}
.icon {
  width: 48px;
  height: 48px;
  object-fit: cover;
}
.text {
  flex: 1 1 0%;
}
.explorar {
  border-radius: 24px;
  font-weight: 400;
  padding: 8px 15px;
}
.slider {
  margin: 0em 10em;
}
.templates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  margin: 2em 4em;
  border-radius: 8px;
  align-items: stretch;
  padding: 1.5em;
  justify-content: space-evenly;
  box-shadow: rgb(79 81 110 / 30%) 0px 2px 4px;
  gap: 1em;
  margin-bottom: 3em;
  padding-bottom: 2em;
}
.card {
  width: 270px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  height: 272px;
  justify-content: space-between;
  flex-basis: 40%;
  border: 1px solid #dadcee;
}
.encuestacard {
  width: 270px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  border-radius: 8px;
  height: 100%;
  flex-basis: 40%;
  border: 1px solid #dadcee;
  cursor: pointer;
}
.red {
  display: inline-flex;
  border-radius: 8px;
  justify-content: center;
  height: 24px;
  align-items: center;
  padding: 0px 12px;
  background-color: rgb(248, 200, 166);
  color: rgb(248, 105, 0);
}
