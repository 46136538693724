.icon {
  height: 35px;
  width: 35px;
}
.photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin: 1rem auto;
}
