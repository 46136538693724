.content {
  width: 89.5vw;
  height: 92vh;
  margin-top: 8rem;
  padding: 1em;
  position: relative;
}
.dashboards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2em;
}
.dashboard {
  flex-basis: 25%;
  background-color: white;
  display: flex;
  flex-direction: column;
  color: black;
  align-items: center;
  min-height: 100px;
  border-radius: 10px;
  justify-content: space-between;
  padding: 1em;
  box-shadow: 1px 2px 4px 3px rgba(0, 0, 0, 0.4);
  font-weight: 600;
  cursor: pointer;
}
.pagination {
  position: absolute;
  top: 70vh;
  right: 35vw;
}
